import * as S from './styles';

export interface CommentsUsersProps {
    comment: string;
    user: string;
    reference: string;
}


export const CommentsUsers = ({ comment, user, reference }: CommentsUsersProps) => {

    const repeatStars = (times: number) => {
        let stars = [];
        for (let i = 0; i < times; i++) {
            stars.push(<img key={i} src={starIcons} alt="" />);
        }
        return stars;
    };

    const starIcons = require('assets/svg/StarIcon.svg').default;

    return (
        <S.Container>
            <S.Comments>
                <S.Title>O que dizem os nossos moradores</S.Title>
                <div style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
                    <S.Comment>{comment}</S.Comment>
                    <S.UserContainer>
                        <S.UserName>{user}</S.UserName>
                        <S.UserReference>{reference}</S.UserReference>
                        <S.Avaliations>{repeatStars(5)}</S.Avaliations>
                    </S.UserContainer>
                </div>
            </S.Comments>
        </S.Container>
    );
};