import useAlert from "components/Notification";
import { useSessionStorage } from "context/sessionStorage";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { formatDateMapped } from "utils/formatDate";
import { DayButton } from "./styles";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import { BlockedDate, CalendarDateStatus, CalendarHook } from "./types";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const useCalendar = ({ blockedDates, date,
    setDate, minimumNights }: CalendarHook) => {
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);
    const [blockedDatesState, setBlockedDatesState] = useState<BlockedDate[]>(
        blockedDates ? blockedDates.map((dateObj: CalendarDateStatus) => ({
            date: dayjs(dateObj.date).startOf('day').toDate(), // Usa dayjs para normalizar a data e garantir que ignore o tempo
            status: dateObj.status
        })) : []
    );

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [nextMonth, setNextMonth] = useState(new Date().getMonth() + 1);
    const [nextYear, setNextYear] = useState(new Date().getFullYear());
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

    const calendarRef = useRef<HTMLDivElement>(null);
    const inputsRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);


    const { saveToSessionStorage, getFromSessionStorage, removeFromSessionStorage } = useSessionStorage()
    const { showAlert, Alert } = useAlert({ active: false, title: '', type: 'SUCCESS' })


    useEffect(() => {
        if (!blockedDates) return;

        const blockedDatesStateTransformed = blockedDates.map(dateObj => ({
            date: dayjs(dateObj.date).startOf('day').toDate(),
            status: dateObj.status
        }));

        setBlockedDatesState(blockedDatesStateTransformed);

        const blockDates = blockedDatesStateTransformed.map(blockedDate =>
        (
            {
                date: dayjs(blockedDate.date).format('YYYY-MM-DD'),
                status: blockedDate.status
            })
        ).filter(blockedDate => blockedDate.status !== 'checkin' && blockedDate.status !== 'checkout').map(blockedDate => blockedDate.date);

        const startFormatted = dayjs(selectedStartDate).format('YYYY-MM-DD');
        const endFormatted = dayjs(selectedEndDate).format('YYYY-MM-DD');

        if (blockDates.includes(startFormatted) || (blockDates.includes(startFormatted) && blockDates.includes(endFormatted))) {
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setDate([null, null]);
            return;
        }

    }, [blockedDates]);

    useEffect(() => {
        if (!blockedDatesState) return;

        const blockDates = blockedDatesState.map(blockedDate =>
        (
            {
                date: dayjs(blockedDate.date).format('YYYY-MM-DD'),
                status: blockedDate.status
            })
        ).filter(blockedDate => blockedDate.status !== 'checkin' && blockedDate.status !== 'checkout').map(blockedDate => blockedDate.date);

        const startFormatted = dayjs(selectedStartDate).format('YYYY-MM-DD');
        const endFormatted = dayjs(selectedEndDate).format('YYYY-MM-DD');

        if (blockDates.includes(startFormatted) || (blockDates.includes(startFormatted) && blockDates.includes(endFormatted))) {
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setDate([null, null]);
            return;
        }
    }, [selectedStartDate, selectedEndDate]);


    useEffect(() => {
        if (!selectedStartDate || !selectedEndDate) return;
        if (!minimumNights) return;
        const difference = dayjs(selectedEndDate).diff(dayjs(selectedStartDate), 'day');
        if (difference < minimumNights) {
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setDate([null, null]);
            return;
        }
    }, [minimumNights, selectedStartDate, selectedEndDate])



    useEffect(() => {
        const blockDates = blockedDatesState.map(blockedDate =>
        (
            {
                date: dayjs(blockedDate.date).format('YYYY-MM-DD'),
                status: blockedDate.status
            })
        ).filter(blockedDate => blockedDate.status !== 'checkin' && blockedDate.status !== 'checkout').map(blockedDate => blockedDate.date);

        const startFormatted = dayjs(selectedStartDate).format('YYYY-MM-DD');
        const endFormatted = dayjs(selectedEndDate).format('YYYY-MM-DD');


        if (blockDates.includes(startFormatted) || (blockDates.includes(startFormatted) && blockDates.includes(endFormatted))) {
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setDate([null, null]);
            return;
        }

        if (!selectedStartDate && !selectedEndDate) {
            const headerDate = getFromSessionStorage('dateHeader')
            if (!headerDate) return;

            const startFormattedHeaderDate = dayjs(headerDate.check_in).format('YYYY-MM-DD');
            const endFormattedHeaderDate = dayjs(headerDate.check_out).format('YYYY-MM-DD');

            if (blockDates.includes(startFormattedHeaderDate) || (blockDates.includes(startFormattedHeaderDate) && blockDates.includes(endFormattedHeaderDate))) {
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                setDate([null, null])
                return;
            }
            setSelectedStartDate(dayjs(headerDate.check_in).toDate())
            setSelectedEndDate(dayjs(headerDate.check_out).toDate())
            setDate([dayjs(headerDate.check_in).format('DD-MM-YYYY'), dayjs(headerDate.check_out).format('DD-MM-YYYY')])
            setCurrentMonth(dayjs(headerDate.check_in).month())
            setCurrentYear(dayjs(headerDate.check_in).year())


            // Atualize o próximo mês corretamente se necessário
            const newNextMonth = dayjs(headerDate.check_in).month() + 1 > 11 ? 0 : dayjs(headerDate.check_in).month() + 1;
            setNextMonth(newNextMonth);
            if (newNextMonth === 0) {
                setNextYear(dayjs(headerDate.check_in).year() + 1);
            } else {
                setNextYear(dayjs(headerDate.check_in).year());
            }

        }
    }, [blockedDates])

    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
            saveToSessionStorage('dateHeader',
                { check_in: formatDateMapped(selectedStartDate), check_out: formatDateMapped(selectedEndDate) })
        }
        setDate([selectedStartDate, selectedEndDate])
    }, [selectedStartDate, selectedEndDate])


    const handleClearDates = () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        removeFromSessionStorage('dateHeader')
    }

    const isDateBlocked = (day: Date): boolean => {
        const blockedIndex = blockedDatesState.findIndex(blockedDate =>
            dayjs(day).isSame(dayjs(blockedDate.date), 'day')
        );

        if (blockedIndex === -1) return false;

        const blockedDate = blockedDatesState[blockedIndex];

        const isBlockedStatus = blockedDate.status === 'ocupada' || blockedDate.status === 'checkin-checkout';

        const nextDate = blockedDatesState[blockedIndex + 1];
        const isNextDateCheckinCheckout = nextDate && nextDate.status === 'checkin-checkout' && dayjs(nextDate.date).diff(dayjs(blockedDate.date), 'day') === 1;

        const isBlockedByCheckoutRule = (blockedDate.status === 'checkout' || blockedDate.status === 'checkin-checkout') && isNextDateCheckinCheckout;

        return isBlockedStatus || isBlockedByCheckoutRule;
    };



    const isCheckinDate = (day: Date): boolean => {
        return blockedDatesState.some(blockedDate =>
            dayjs(day).isSame(dayjs(blockedDate.date), 'day') && // Compara apenas a data, ignorando o tempo
            blockedDate.status === 'checkin'
        );
    };

    const isCheckoutDate = (day: Date): boolean => {
        return blockedDatesState.some(blockedDate =>
            dayjs(day).isSame(dayjs(blockedDate.date), 'day') &&
            blockedDate.status === 'checkout'
        );
    };

    const isAnyDateDisabledInRange = (start: Date, end: Date): boolean => {
        let currentDate = new Date(start);

        while (currentDate <= end) {
            if (isDateBlocked(currentDate)) {
                return true;
            }
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        }

        return false;
    };

    const handleDayClick = (day: Date) => {
        if (selectedStartDate && isAnyDateDisabledInRange(selectedStartDate, day)) {
            showAlert({ active: true, title: 'Ops! As datas selecionadas não estão disponíveis.', type: 'ERROR' });
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            return;
        }

        if (selectedStartDate) {
            const nextCheckout = findNextCheckoutDate(selectedStartDate);
            if (nextCheckout && day > nextCheckout) {
                showAlert({ active: true, title: 'Ops! Não é possível selecionar datas após o próximo checkout.', type: 'ERROR' });
                return;
            }
        }

        if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
            setSelectedStartDate(day);
            setSelectedEndDate(null);
        } else if (selectedStartDate && day < selectedStartDate) {
            setSelectedStartDate(day);
        } else if (selectedStartDate && !selectedEndDate && day > selectedStartDate) {
            setSelectedEndDate(day);
        }
    };

    const dayStartedOnWeekend = (day: Date) => {
        return day.getDay() === 0;
    }

    const handleOpenCalendar = () => {
        if (!openCalendar) {
            setOpenCalendar(true);
        } else {
            return;
        }
    }

    const handleCloseCalendar = () => {
        setOpenCalendar(false);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node) && footerRef.current && !footerRef.current.contains(event.target as Node) && inputsRef.current && !inputsRef.current.contains(event.target as Node)) {
                setOpenCalendar(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }


    }, [])

    const isBeforeToday = (day: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return day < today;
    };

    const enableShortStayAndMidStay = (startDate: Date | null, targetDate: Date): boolean => {
        if (!startDate) return false; // Se não houver uma startDate selecionada, retorna false.

        const THIRTY_DAYS_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000; // 30 dias em milissegundos.
        const endDateLimit = new Date(startDate.getTime() + THIRTY_DAYS_IN_MILLISECONDS);

        return targetDate > endDateLimit;
    };

    const isPrevOrNextMonth = (day: Date, currentMonth: number, currentYear: number) => {
        return day.getMonth() !== currentMonth || day.getFullYear() !== currentYear;
    };

    const PopupWarning = ({ text }: { text: string }) => {
        return (
            <div style={{ position: 'absolute', backgroundColor: NEUTRAL_COLORS.LIGHT_GRAY, padding: '4px 8px', borderRadius: '9px', color: 'black', zIndex: 10, top: -40, fontSize: 12, whiteSpace: 'nowrap', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)' }}>
                {text}
            </div>
        );
    };


    const findNextCheckoutDate = (startDate: Date): Date | null => {
        const nextCheckout = blockedDatesState.find((blockedDate) => {
            return (
                blockedDate.status === 'checkout' &&
                dayjs(blockedDate.date).isAfter(dayjs(startDate), 'day')
            );
        });
        return nextCheckout ? nextCheckout.date : null;
    };

    const isMinimumStayNotMet = (day: Date): boolean => {
        if (!selectedStartDate) return false;
        if (!minimumNights) return false;

        // Se selectedEndDate está definido e a diferença é maior ou igual ao mínimo de noites, não bloqueia
        if (selectedEndDate && dayjs(selectedEndDate).diff(dayjs(selectedStartDate), 'day') >= minimumNights) {
            return false;
        }

        // Calcula a data limite mínima com base em minimumNights
        const minimumEndDate = dayjs(selectedStartDate).add(minimumNights, 'day').toDate();

        // Verifica se o dia atual (selectedStartDate) não é bloqueado
        if (dayjs(day).isSame(dayjs(selectedStartDate), 'day')) {
            return false; // Não bloqueia o selectedStartDate
        }

        // Verifica se o dia está dentro do período mínimo de noites
        return dayjs(day).isBefore(minimumEndDate, 'day');
    };


    function renderMonth(days: Date[], month: number, year: number) {
        const nextCheckout = selectedStartDate ? findNextCheckoutDate(selectedStartDate) : null;
        const blockUntilDate = selectedStartDate ? dayjs(selectedStartDate).add(30, 'day').toDate() : null;

        return days.map((day, index) => {
            if (day === null) {
                return <DayButton disabled key={index} />;
            }
            const isBlocked = isDateBlocked(day);
            const isBefore = selectedStartDate ? day < selectedStartDate : isBeforeToday(day);
            const isAfterNextCheckout = nextCheckout ? dayjs(day).isSameOrAfter(dayjs(nextCheckout), 'day') : false; // Bloqueia o próximo checkout e posteriores
            const isPrevOrNext = isPrevOrNextMonth(day, month, year);
            const isWithinThirtyDaysAfterStart =
                blockUntilDate ? dayjs(day).isAfter(dayjs(blockUntilDate), 'day') : false;
            const isMinimumStayBlocked = isMinimumStayNotMet(day);

            const isSelectedStart = selectedStartDate && day.toString() === selectedStartDate.toString();
            const isSelectedEnd = selectedEndDate && day.toString() === selectedEndDate.toString();
            const isBetween = selectedStartDate && selectedEndDate && day > selectedStartDate && day < selectedEndDate;

            const isCheckin = isCheckinDate(day); // Verifica se é um dia de check-in

            const isCheckout = isCheckoutDate(day); // Verifica se é um dia de check-out

            const isValidMinimumStayPopup = minimumNights && isSelectedStart && !selectedEndDate

            return (
                <DayButton
                    isCheckin={isCheckin} // Passa o status checkin para o DayButton
                    isCheckout={isCheckout} // Passa o status checkout para o DayButton
                    data-cy={dayjs(day).format('YYYY-MM-DD')}
                    daysStartedOnWeekend={dayStartedOnWeekend(day)}
                    isBlocked={isBlocked || isAfterNextCheckout || isBefore || isWithinThirtyDaysAfterStart || isMinimumStayBlocked}
                    isBefore={isBefore || isAfterNextCheckout || isBlocked || isWithinThirtyDaysAfterStart || isMinimumStayBlocked}
                    isBetween={isBetween as boolean}
                    isPrevOrNext={isPrevOrNext}
                    isSelectedEnd={isSelectedEnd as boolean}
                    isSelectedStart={isSelectedStart as boolean}
                    disabled={isBefore || isAfterNextCheckout || isBlocked || isWithinThirtyDaysAfterStart || isMinimumStayBlocked}
                    key={index}
                    onClick={() => handleDayClick(day)}
                >
                    {day.getDate()}
                    {isValidMinimumStayPopup && <PopupWarning text={`Mínimo ${minimumNights} noites`} />}
                    {isCheckin && isSelectedStart && <PopupWarning text="Somente saída" />}
                </DayButton>
            );
        });
    }


    const handlePrevMonth = (): void => {
        let newYear = currentYear;
        let newMonth = currentMonth - 1;

        if (currentMonth === 0) {
            newMonth = 11; // Dezembro do ano anterior
            newYear = currentYear - 1;
        }

        setCurrentMonth(newMonth);
        setCurrentYear(newYear);

        // Atualize o próximo mês corretamente se necessário
        const newNextMonth = newMonth + 1 > 11 ? 0 : newMonth + 1;
        setNextMonth(newNextMonth);
        if (newNextMonth === 0) {
            setNextYear(newYear + 1);
        } else {
            setNextYear(newYear);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
            setNextMonth(1);
        } else if (nextMonth === 11) {
            setNextMonth(0);
            setNextYear(nextYear + 1);
            setCurrentMonth(11);
        } else {
            setCurrentMonth(currentMonth + 1);
            setNextMonth(nextMonth + 1);
        }
    };

    return {
        blockedDates: blockedDatesState,
        isDateBlocked,
        handleOpenCalendar,
        openCalendar,
        handleCloseCalendar,
        calendarRef,
        inputsRef,
        footerRef,
        isBeforeToday,
        enableShortStayAndMidStay,
        currentMonth,
        currentYear,
        nextMonth,
        nextYear,
        handlePrevMonth,
        handleNextMonth,
        handleClearDates,
        renderMonth,
        selectedStartDate,
        selectedEndDate,
        Alert
    }
}